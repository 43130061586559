/* global axios */
export default class MessageService {
  constructor() {
    this.axios = axios;
  }
  save(id, messages) {
    return this.axios.post(`service/messages/save/${id}`, {
      messages
    });
  }
}
