<template>
  <div v-if="!isLoading" id="messages">
    <header-service
      :service="service"
      currentTab="Mensagens"
      :isSaving="!isLoading && isSaving"
      @saving="save"
    ></header-service>
		<div class="row mt-5">
      <div class="col-lg-3 col-md-4">
        <menu-service :service="service"></menu-service>
      </div>
      <div class="col-lg-9 col-md-8" ref="form">
        <!-- START MESSAGE -->
        <div class="row mt-4" v-for="(message, messageIndex) in messages" :key="messageIndex">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h5 class="mb-4">{{ message.title }}</h5>
                <hr>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>Mensagem</label>
                    <textarea :disabled="inputDisable" class="form-control" v-model="message.message"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END MESSAGE -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import MultiSelect from 'vue-multiselect';
import MenuService from '../components/MenuService';
import ServiceService from '../services/ServiceService';
import MessageService from '../services/MessageService';
import HeaderService from '../components/HeaderService';

export default {
  name: 'service-keywords',
  components: {
    HeaderService,
    MenuService,
    MultiSelect,
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      service: {},
      serviceMessages: {},
      serviceService: new ServiceService(),
      messageService: new MessageService(),
      inputDisable: !this.$authorization.can('service-update'),
      messages: [
        {
          message_id: null,
          title: '2º SMS de confirmação de assinatura (Modelo no Manual Claro)',
          message:''
        },
        {
          message_id: null,
          title: 'Ajuda (Menu + Opções)',
          message: ''
        },
        {
          message_id: null,
          title: 'Comando Inválido (Não Assinantes + Assinantes)',
          message: ''
        },
      ],
    };
  },
  created() {
    this.getService();
  },
  watch: {
    '$store.state.service.lock': function(newValue, oldValue){
      this.lockForm(newValue);
    }
  },
  methods: {
    /**
     * Get Service Info
     */
    getService() {
      this.serviceService.getService(this.$route.params.id)
        .then((response) => {
          this.service = response.data;
          this.serviceMessages = response.data.messages;
          this.setMessagesSaved();
          this.isLoading = false;
          this.preventEditForm();
        });
    },
    save() {
      this.isSaving = true;
      this.messageService.save(this.service.id, this.messages)
        .then((response) => {
          this.getService();
          this.isSaving = false;
          swal('Sucesso!', 'Serviço atualizado com sucesso.', 'success');
        })
        .catch((error) => {
          this.isSaving = false;
          console.log(error.response);
          swal( 'Ops!' ,  'Dados Inválidos. Verifique os dados informados e tente novamente.' ,  'error');
        })
    },
    setMessagesSaved() {
      if (this.serviceMessages.length) {
        this.serviceMessages.forEach((message, index) => {
          this.messages[index].id = message.id;
          this.messages[index].message = message.message;
        });
      }
    },
    lockForm(isLocked) {
      const form = $(this.$refs.form);

      if (isLocked) {
        form.find('input, textarea, select').prop('disabled', true);
      } else {
        form.find('input, textarea, select').prop('disabled', false);
      }
    },

    preventEditForm() {
      setTimeout(() => {
        if(this.service.status_register === 'complete') {
          this.$store.commit('setLock', true);
          this.lockForm(true);
        }
      }, 0)
    }
  },
};
</script>
